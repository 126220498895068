import React, { useState } from "react"
import { motion, AnimatePresence } from "framer-motion"
import classnames from "classnames"
import slug from "slug"


/* Import Global Components */
import BlockContent from "~components/blockContent/blockContent"

/* Import Global Animation(s) */
import elementTransitionAnimation from "~animations/elementTransition"


import Partners from "~components/pages/about/components/partnerTypes/partnerTypes"

const AboutSection = ({ aboutPanels, partnerTypes }) => {

	const [panel, setPanel] = useState(slug(aboutPanels[0]?.title || ""))


	return (
		<section>
			<div className="panel-selectors">
        {aboutPanels.map(({ title, _key }) => (
          <button
            className={classnames('font-size--small', { "is-active": panel === slug(title || "") })}
            onClick={() => {
              setPanel(slug(title))
            }}
            key={_key}
          >
            {title}
          </button>
        ))}
        {partnerTypes.length > 0 && <button
          className={classnames('font-size--small', { "is-active": panel === slug("Partners") })}
          onClick={() => {
            setPanel(slug("Partners"))
          }}
          key={"partners"}
        >
          Partners
        </button>}
      </div>
      <AnimatePresence initial={false} exitBeforeEnter>
        {aboutPanels.map(({ title, _rawCopy, _key }) => {
          return panel === slug(title) ? (
            <motion.div
              className={classnames("panel text_container", `${slug(title)}-panel`)}
              initial="initial"
              animate="animate"
              exit="exit"
              variants={elementTransitionAnimation.variants}
              key={_key + '_panel'}
            >
              <BlockContent blocks={_rawCopy} />
            </motion.div>
          ) : null
        })}



        {partnerTypes.length > 0 && panel === slug("Partners") ? (
          <motion.div
            className={classnames("panel text_container", `${slug("Partners")}-panel`)}
            initial="initial"
            animate="animate"
            exit="exit"
            variants={elementTransitionAnimation.variants}
            key={"partners-panel"}
          >
            <Partners partnerTypes={partnerTypes} />
          </motion.div>
        ) : null}
      </AnimatePresence>

		</section>
	)

}

export default AboutSection