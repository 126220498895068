import React from "react"
import { motion, AnimatePresence } from "framer-motion"

/* Import Global Animation(s) */
import elementTransitionAnimation from "~animations/elementTransition"

/* Import Local Components*/
import GridPartner from "./components/gridPartnerItem/gridPartnerItem"

/* Import Local Style */
import "./partner-types.scss"

const PartnerTypes = ({ partnerTypes = {} }) => {
  return (
    <AnimatePresence exitBeforeEnter>
        <motion.ul
          className="partners partners-grid"
          key="list"
          initial="initial"
          animate="animate"
          exit="exit"
          variants={elementTransitionAnimation.variants}
        >
          <AnimatePresence initial={false} exitBeforeEnter>
            {partnerTypes.map((partnerType) => {
              return <motion.li
                  className="partners__gallery-group"
                  key={partnerType.name + '_type'}
                  initial="initial"
                  animate="animate"
                  exit="exit"
                  variants={elementTransitionAnimation.variants}
                >
                  <div className="partners__gallery-group__title">
                    {partnerType.name}
                  </div>
                  <motion.ul>
                    <AnimatePresence initial={false} exitBeforeEnter>
                      {partnerType.partners.map(partner => (
                        <motion.li
                          key={partner._id}
                          initial="initial"
                          animate="animate"
                          exit="exit"
                          variants={elementTransitionAnimation.variants}
                        >
                          <GridPartner partner={partner} />
                        </motion.li>
                      ))}
                    </AnimatePresence>
                  </motion.ul>
                </motion.li>
            })}
          </AnimatePresence>
        </motion.ul>
    </AnimatePresence>
  )
}
export default PartnerTypes
