import React from "react"
import Image from "~components/image/image"

/* Import Local Style(s) */
import "./grid-partner-item.scss"

const GridPartnerItem = ({ partner: { name, url, image } }) => {
  return (
    <a
      href={url}
      target="_blank"
      rel="noreferrer"
      className="grid-partner-item"
    >
      <div className="grid-partner-item__image-wrapper">
        {image && image.asset && (
          <div className="grid-partner-item__image-wrapper__inner">
            <Image image={image.asset.gatsbyImageData} objectFit="cover" />
          </div>
        )}
      </div>
      {/* <p>{name}</p> */}
    </a>
  )
}

export default GridPartnerItem
